import {
  makeStyles,
  Tab,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  Tabs,
} from '@material-ui/core'
import debounce from 'lodash/debounce'
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { DndProvider, useDrag, useDrop } from 'react-dnd'
import Api from 'rest-fetcher-redux'
import {
  AddressAutocomplete,
  Amenities,
  Button,
  Modal,
  ModalComponent,
  MODALS,
  SquareIconButton,
  TextInput,
  Typography,
  useFieldMenuNew,
} from '~/legacy/components'
import { DragIcon } from '~/legacy/components/svgs'
import {
  EditableCell,
  TableContentCell,
  TableHeaderCell,
  TableHeaderRow,
  TableHeaderText,
  TableRow,
} from '~/legacy/components/tableComponents'
import { EditLocationForm } from '~/legacy/pages/Surveys/Survey/EditLocationForm'
import {
  arrayMove,
  BACKEND_FOR_DND,
  getBuildingPrimaryName,
  getIconByDataTypeId,
  getPlural,
} from '~/legacy/utils'
import { useStupidRefresh } from '~/support/useStupidRefresh'
import FullScreenTitle from './FullScreenTitle'
import { AddCustomFieldTableRow } from './shared/AddCustomFieldTableRow'

const useStyles = makeStyles({
  tabs: {
    // padding: '0px',
    borderBottom: '1px solid #e0e0e0',
    // backgroundColor: '#ffffff',
    // position: 'sticky',
    // width: '100%',
  },
  tab: {
    minWidth: 'unset',
  },
  modal: {
    width: '100vw',
    overflow: 'hidden',
    '&:first-child': {
      padding: '0',
    },
  },
  body: {
    // width: '100%',
    width: '100%',
    marginTop: '60px', // for the header
    paddingTop: '70px',
    overflowY: 'auto',
    display: 'flex',
  },
  innerBody: {
    // maxWidth: '640px',
    width: '640px',
    height: 'fit-content',
    margin: '0 auto',
    marginBottom: '80px',
    display: 'flex',
    flexDirection: 'column',
  },
  title: {
    paddingBottom: '24px',
    width: '100%',
    // borderBottom: '1px solid #e0e0e0',
  },
  formArea: {
    display: 'grid',
    gap: '28px',
    padding: '40px 0',
    borderBottom: '1px solid #e0e0e0',
  },
  tableArea: {
    marginBottom: '40px',
  },
  tableAreaHeader: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '100%',
    padding: '16px 0',
  },
  addFieldButton: {
    minWidth: '0',
    height: '36px',
    textTransform: 'none',
  },
  addFieldButtonIcon: {
    marginLeft: 0,
  },
  addFieldMenu: {
    marginTop: '8px',
  },
  metadataTable: {
    tableLayout: 'fixed',
    borderRadius: '4px',
    borderCollapse: 'separate',
    border: '1px solid #E0E0E0',

    '& tr': {
      '& th:not(:last-child), td:not(:last-child)': {
        borderRight: '1px solid #e0e0e0',
      },
    },

    // Table border radiuses
    '& tr:first-child th:first-child': {
      borderTopLeftRadius: '4px',
    },
    '& tr:first-child th:last-child': {
      borderTopRightRadius: '4px',
    },
    '& tr:last-child td:first-child': {
      borderBottomLeftRadius: '4px',
    },
    '& tr:last-child td:last-child': {
      borderBottomRightRadius: '4px',
    },
  },
  metadataKeyCell: {
    paddingLeft: '10px',
    paddingRight: '22px',
  },
  metadataKeyCellFieldName: {
    paddingLeft: '8px',
  },
  metadataKey: {
    display: 'flex',
    alignItems: 'center',
  },
  metadataKeyIcon: {
    marginRight: '12px',
    '&:hover': {
      backgroundColor: '#f3f3f3',
    },
  },
  metadataKeyText: {
    marginLeft: '8px',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  editableCell: {
    paddingLeft: '12px',
  },
  deleteFieldMenuSection: {
    marginTop: '20px',
  },
  deleteFieldMenuTitle: {
    marginLeft: '12px',
    marginBottom: '6px',
  },
  menuItem: {
    paddingLeft: '12px',
    paddingRight: '12px',
    alignItems: 'center',
  },
  fieldNameHeader: {
    paddingLeft: '38px',
  },
  dragIcon: {
    cursor: 'grab',
  },
})

const useEditableCellStyles = makeStyles({
  container: {
    height: '36px',
    paddingLeft: (props) => (props.isName ? '8px' : '12px'),
    cursor: 'pointer',
    marginTop: (props) => props.isKey && '1px',
    marginRight: '16px',
  },
  value: {
    padding: 0,
  },
  valueHovered: {
    backgroundColor: '#f3f3f3',
  },
  valueInput: {
    margin: 0,
    marginTop: '1px',
  },
  valueClearInputIcon: {
    color: '#e0e0e0',
    '&:hover': {
      color: '#e0e0e0',
    },
  },
})

function CustomFieldRow({
  index,
  surveyId,
  fieldData,
  valueData,
  building,
  setFieldMenuAnchorEl,
  renameCustomField,
  uniqueFieldNames,
  createCustomFieldValue,
  updateCustomFieldValue,
  moveField,
  saveFieldOrder,
}) {
  const classes = useStyles()
  const editableCellKeyClasses = useEditableCellStyles({ isKey: true })
  const editableCellClasses = useEditableCellStyles()

  const fieldId = fieldData ? fieldData.id : null
  const fieldType = fieldData.data_type
  const fieldName = fieldData.label
  const fieldValue = valueData ? valueData.value : null
  const IconClass = getIconByDataTypeId(fieldType)
  const surveyBuildingCustomFieldId = valueData ? valueData.id : null
  const customFieldValueId = valueData ? valueData.custom_field_value_id : null

  const openMenu = (event) =>
    setFieldMenuAnchorEl({
      anchor: event.currentTarget,
      fieldName,
      buildingName: getBuildingPrimaryName(building),
      modelFieldName: null,
      fieldId,
      fieldType,
      fieldValue,
      customFieldValueId,
    })

  const ref = useRef(null)
  const previewRef = useRef(null)
  const ItemTypes = {
    CARD: 'card',
  }

  const [{ isDragging }, drag, preview] = useDrag({
    item: { type: ItemTypes.CARD, index },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  })

  const [{ handlerId }, drop] = useDrop({
    accept: ItemTypes.CARD,
    collect(monitor) {
      return {
        handlerId: monitor.getHandlerId(),
      }
    },
    hover(item) {
      if (!ref.current) {
        return
      }
      const dragIndex = item.index
      const hoverIndex = index
      // Don't replace items with themselves
      if (dragIndex === hoverIndex) {
        return
      }
      moveField(dragIndex, hoverIndex)
      // eslint-disable-next-line
      item.index = hoverIndex
    },
    drop: () => {
      saveFieldOrder()
    },
  })
  const opacity = isDragging ? 0 : 1
  drag(ref)
  drop(previewRef)
  preview(previewRef)

  return (
    <TableRow
      ref={previewRef}
      data-handler-id={handlerId}
      style={{
        opacity,
      }}
    >
      <TableContentCell
        classes={{ tableCell: classes.metadataKeyCellFieldName }}
      >
        <div className={classes.metadataKey}>
          <div ref={ref} style={{ display: 'flex', paddingRight: '6px' }}>
            <DragIcon className={classes.dragIcon} />
          </div>
          <SquareIconButton onClick={openMenu}>
            <IconClass />
          </SquareIconButton>
          {fieldData ? (
            <EditableCell
              value={fieldName}
              updateValue={() => {}}
              updateValueApi={(newName) =>
                new Promise((resolve) => {
                  if (newName && !uniqueFieldNames.has(newName)) {
                    renameCustomField({
                      id: fieldId,
                      newName,
                    })
                    resolve(newName)
                  } else {
                    resolve()
                  }
                })
              }
              classesIn={editableCellKeyClasses}
              textContentVariant="bodyBold"
            />
          ) : (
            <Typography variant="bodyBold" className={classes.metadataKeyText}>
              {fieldName}
            </Typography>
          )}
        </div>
      </TableContentCell>
      <TableContentCell classes={{ tableCell: classes.editableCell }}>
        <EditableCell
          value={fieldValue}
          updateValueApi={(newValue) =>
            new Promise((resolve) => {
              if (!customFieldValueId) {
                createCustomFieldValue({
                  surveyId,
                  buildingId: building.id,
                  customFieldId: fieldId,
                  newValue,
                })
              } else {
                updateCustomFieldValue({
                  id: surveyBuildingCustomFieldId,
                  newValue,
                })
              }
              resolve(newValue)
            })
          }
          fieldType={fieldType}
          classesIn={editableCellClasses}
        />
      </TableContentCell>
    </TableRow>
  )
}

export default function EditBuildingModalNew({
  surveyId,
  surveyName,
  surveyBuilding,
  building,
  mutate,
  updateBuilding,
  createCustomField,
  deleteCustomField,
  renameCustomField,
  changeCustomFieldDataType,
  setFieldOrder,
  ModalComponentProps,
  customFieldValues,
  customFieldDefinitions,
  createCustomFieldValue,
  updateCustomFieldValue,
}) {
  const classes = useStyles()
  const editableCellClasses = useEditableCellStyles()

  // store building state locally for a few values, so we can debounce updates
  const [buildingData, setBuildingData] = useState(building)
  const buildingPrimaryName = useMemo(
    () => getBuildingPrimaryName(buildingData),
    [buildingData]
  )

  // TODO: do we need this?
  const [newAddressValues, setNewAddressValues] = useState(building)
  const [addressErrorText, setAddressErrorText] = useState('')

  const [showAddFromAnotherModal, setShowAddFromAnotherModal] = useState(false)
  const [addingNewField, setAddingNewField] = useState(false)
  const [orderedCustomFields, setOrderedCustomFields] = useState(
    customFieldDefinitions
  )

  const uniqueFieldNames = new Set(
    customFieldDefinitions
      ? customFieldDefinitions.map((item) => item.label)
      : []
  )

  const valuesMap = {}
  customFieldValues.forEach((item) => {
    valuesMap[item.custom_field.id] = item
  })

  const lastOrder =
    customFieldDefinitions && customFieldDefinitions.length > 0
      ? customFieldDefinitions[customFieldDefinitions.length - 1].order
      : undefined

  useEffect(() => {
    setOrderedCustomFields(customFieldDefinitions)
  }, [customFieldDefinitions])

  const debouncedUpdateBuilding = useCallback(
    debounce(
      (newData, updateBuildingLocal) => updateBuildingLocal(newData),
      1000
    ),
    []
  )

  const updateBuildingData = (newData) => {
    setBuildingData({ ...buildingData, ...newData })
    debouncedUpdateBuilding(newData, updateBuilding)
  }

  const {
    setAnchorMenuEl: setFieldMenuAnchorEl,
    SpaceFieldMenuComponent: FieldMenuComponent,
    DeleteSpaceFieldModalComponent: DeleteFieldModalComponent,
    ConfirmChangeFieldTypeModalComponent,
  } = useFieldMenuNew({
    surveyName,
    isBuildingField: true,
    deleteField: ({ modelFieldName, fieldId }) => {
      if (modelFieldName) {
        // Delete a regular field
        return updateBuilding({ [modelFieldName]: null })
      }
      // Delete a custom field
      return deleteCustomField({ id: fieldId })
    },
    changeCustomFieldDataType,
  })

  const moveCustomField = useCallback(
    (dragIndex, hoverIndex) => {
      setOrderedCustomFields((a) => arrayMove(a, dragIndex, hoverIndex))
    },
    [setOrderedCustomFields]
  )

  const saveCustomFieldOrder = useCallback(async () => {
    const localNewFieldOrder = {}
    orderedCustomFields.forEach((cf, index) => {
      localNewFieldOrder[cf.id] = index
    })
    setFieldOrder({ fieldOrder: localNewFieldOrder })
  }, [setFieldOrder, orderedCustomFields])

  const [selectedTab, setSelectedTab] = useState(0)

  useStupidRefresh()

  return (
    <ModalComponent
      classesIn={{ dialogContent: classes.modal }}
      fullScreen
      {...ModalComponentProps}
    >
      <FullScreenTitle
        title={buildingPrimaryName}
        onClose={ModalComponentProps.onClose}
      />
      <div className={classes.body}>
        <div className={classes.innerBody}>
          <Typography className={classes.title} variant="pageTitle">
            Building Details
          </Typography>
          <Tabs
            className={classes.tabs}
            value={selectedTab}
            onChange={(_, newIndex) => setSelectedTab(newIndex)}
            indicatorColor="primary"
          >
            <Tab className={classes.tab} label="Overview" />
            <Tab className={classes.tab} label="Location" />
          </Tabs>
          {selectedTab === 0 && (
            <div>
              <div className={classes.formArea}>
                <AddressAutocomplete
                  autoFocus={false}
                  label="Address"
                  error={!!addressErrorText}
                  helperText={addressErrorText}
                  fetchFreshBuildingData={false}
                  controlledValue={newAddressValues}
                  onBlur={() => {
                    if (!newAddressValues.address) {
                      setNewAddressValues(building)
                    }
                  }}
                  setAddressValues={(newValues) => {
                    setAddressErrorText('')
                    setNewAddressValues(newValues)
                    if (newValues.address) {
                      Api.updateBuildingAddress({
                        id: building.id,
                        body: {
                          ...newValues,
                        },
                      }).then((res) => {
                        if (res.data.error) {
                          setAddressErrorText(res.data.error)
                        } else {
                          setBuildingData({ ...buildingData, ...res.data })
                          updateBuilding({ ...res.data })
                        }
                      })
                    }
                  }}
                />
                <TextInput
                  label="Building Name"
                  onChange={(e) => updateBuildingData({ name: e.target.value })}
                  value={buildingData.name}
                />
                <TextInput
                  label="Notes"
                  multiline
                  rows={6}
                  onChange={(e) =>
                    updateBuildingData({ description: e.target.value })
                  }
                  value={buildingData.description}
                />
              </div>
              <div className={classes.tableArea}>
                <div className={classes.tableAreaHeader}>
                  <Typography variant="boldText">
                    {getPlural(customFieldDefinitions.length, 'field')}
                  </Typography>
                  <Button
                    className={classes.addFieldButton}
                    classes={{ endIcon: classes.addFieldButtonIcon }}
                    color="primary"
                    onClick={() => setAddingNewField(true)}
                  >
                    Add Field
                  </Button>
                </div>

                {orderedCustomFields.length > 0 && (
                  <TableContainer>
                    <Table size="small" className={classes.metadataTable}>
                      <colgroup>
                        <col style={{ width: 'calc(50% - 16px)' }} />
                        <col style={{ width: 'calc(50% - 16px)' }} />
                      </colgroup>
                      <TableHead>
                        <TableHeaderRow>
                          <TableHeaderCell
                            classes={{ tableCell: classes.fieldNameHeader }}
                          >
                            <TableHeaderText>FIELD NAME</TableHeaderText>
                          </TableHeaderCell>
                          <TableHeaderCell>
                            <TableHeaderText>FIELD VALUE</TableHeaderText>
                          </TableHeaderCell>
                        </TableHeaderRow>
                      </TableHead>
                      <TableBody>
                        {orderedCustomFields.map((customField, index) => (
                          <DndProvider
                            backend={BACKEND_FOR_DND}
                            key={customField.id}
                          >
                            <CustomFieldRow
                              key={customField.id}
                              index={index}
                              data={customField}
                              fieldData={customField}
                              valueData={valuesMap[customField.id]}
                              surveyId={surveyId}
                              building={building}
                              setFieldMenuAnchorEl={setFieldMenuAnchorEl}
                              renameCustomField={renameCustomField}
                              createCustomFieldValue={createCustomFieldValue}
                              updateCustomFieldValue={updateCustomFieldValue}
                              uniqueFieldNames={uniqueFieldNames}
                              moveField={moveCustomField}
                              saveFieldOrder={saveCustomFieldOrder}
                            />
                          </DndProvider>
                        ))}

                        {addingNewField && (
                          <AddCustomFieldTableRow
                            classes={{
                              metadataKeyCell: classes.metadataKeyCell,
                              editableCell: classes.editableCell,
                            }}
                            objectIds={[building.id]}
                            editableCellClasses={editableCellClasses}
                            createCustomFields={(newCustomFieldData) => {
                              if (
                                newCustomFieldData.name &&
                                !uniqueFieldNames.has(newCustomFieldData.name)
                              ) {
                                createCustomField({
                                  label: newCustomFieldData.name,
                                  order: lastOrder ? lastOrder + 1 : undefined,
                                })
                              }
                              setAddingNewField(false)
                            }}
                            setFieldMenuAnchorEl={setFieldMenuAnchorEl}
                          />
                        )}
                      </TableBody>
                    </Table>
                  </TableContainer>
                )}
              </div>
              <Amenities
                amenities={buildingData.amenities}
                updateAmenities={(newAmenities) => {
                  updateBuildingData({ amenities: newAmenities })
                }}
              />
            </div>
          )}
          {selectedTab === 1 && (
            <EditLocationForm
              building={buildingData}
              padding="30px 0px"
              onChange={updateBuildingData}
            />
          )}
        </div>
      </div>
      <Modal
        content={MODALS.ADD_FIELDS_FROM_ANOTHER}
        onClose={() => setShowAddFromAnotherModal(false)}
        open={showAddFromAnotherModal}
        childProps={{
          // this modal handles updating the building on the backend,
          // mutate just refreshes the local SWR data
          updateCustomFields: (updatedBuildings) =>
            mutate({
              ...surveyBuilding,
              building: { ...updatedBuildings[0] },
            }),
          parentObjectIds: [building.id],
          surveyId,
          type: 'building',
        }}
      />
      {FieldMenuComponent}
      {DeleteFieldModalComponent}
      {ConfirmChangeFieldTypeModalComponent}
    </ModalComponent>
  )
}
