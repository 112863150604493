import { useState, useEffect, useMemo } from 'react'
import useSWR from 'swr'
import _ from 'lodash'
import { defaultMutateBinder, defaultMutateOptimisticBinder } from './selectors'
import { surveyApi, customFieldApi } from '~/legacy/fetchApi'

const DEFAULT_API_ERROR_MESSAGE =
  'Error updating custom fields, please try again.'

// Fetch fresh bdp data from our backend
const fetchSurvey = async (surveyId, page, isAnonymous) => {
  return surveyApi
    .getSurvey({ surveyId, page, skipAuth: isAnonymous })
    .then(([, response]) => response)
}

// Our bdp data in SWR
export const SWR_SURVEY = 'survey'

// Hook to manage the raw Survey Building in SWR as well as format the raw Survey Building.
export const useSurveySelector = (
  surveyBuildingId = null,
  page = null,
  isAnonymous = false
) => {
  // Async wrapper for fetching our bdp data from backend
  const swrArgs = []
  if (surveyBuildingId) {
    swrArgs.push(async () => fetchSurvey(surveyBuildingId, page, isAnonymous))
  }

  // SWR bdp data
  const { data: rawSurvey, error, mutate } = useSWR(SWR_SURVEY, ...swrArgs)
  // Whenever we change/update the bdp data via api, format it
  const [survey, setSurvey] = useState(rawSurvey)

  const {
    customFields,
    buildingCustomFields,
    listingCustomFields,
    buildingTemplateId,
    listingTemplateId,
  } = useMemo(() => {
    const customFields = survey?.custom_fields || []
    const buildingTemplateId =
      survey?.templates && survey?.templates.length > 0
        ? survey.templates.find((t) => t.type === 'SURVEY_BUILDINGS')?.id
        : undefined

    const listingTemplateId =
      survey?.templates && survey?.templates.length > 0
        ? survey.templates.find((t) => t.type === 'SURVEY_SPACES')?.id
        : undefined

    const sortedCustomFields = _.orderBy(
      customFields,
      [(cf) => cf.template.id, (cf) => cf.order],
      ['asc', 'asc']
    )

    const buildingCustomFields = sortedCustomFields.filter(
      (cf) => cf.template.id === buildingTemplateId
    )

    const listingCustomFields = sortedCustomFields.filter(
      (cf) => cf.template.id === listingTemplateId
    )

    return {
      customFields: sortedCustomFields,
      buildingCustomFields,
      listingCustomFields,
      buildingTemplateId,
      listingTemplateId,
    }
  }, [survey])

  // Bind our mutators with our default settings and error handling
  const mutateSurveyCustomFields = defaultMutateBinder(
    mutate,
    DEFAULT_API_ERROR_MESSAGE
  )

  const mutateSurveyCustomFieldsOptimistic = defaultMutateOptimisticBinder(
    mutateSurveyCustomFields,
    DEFAULT_API_ERROR_MESSAGE
  )

  const incorporateCustomFields = (newData) => {
    return {
      ...rawSurvey,
      custom_fields: newData,
    }
  }

  useEffect(() => {
    if (rawSurvey) {
      setSurvey(rawSurvey)
    } else {
      setSurvey(null)
    }
  }, [rawSurvey])

  const mutateChangeCustomFieldType = async ({
    id,
    newType,
    newValue,
    optimistic = true,
  }) => {
    const apiMutator = async () =>
      customFieldApi
        .changeFieldType({
          fieldId: id,
          type: newType,
          value: newValue,
        })
        .then(([, response]) => {
          const index = customFields.findIndex(
            (item) => item.id === response.id
          )

          return incorporateCustomFields([
            ...customFields.slice(0, index),
            response,
            ...customFields.slice(index + 1),
          ])
        })

    if (optimistic) {
      const index = customFields.findIndex((item) => item.id === id)
      const element = customFields[index]

      const newData = incorporateCustomFields([
        ...customFields.slice(0, index),
        { ...element, data_type: newType, value: newValue },
        ...customFields.slice(index + 1),
      ])

      return mutateSurveyCustomFieldsOptimistic({
        newObject: newData,
        mutator: apiMutator,
      })
    }

    return mutateSurveyCustomFields({ mutator: apiMutator })
  }

  const mutateChangeCustomFieldLabel = async ({
    id,
    newName,
    optimistic = true,
  }) => {
    const apiMutator = async () =>
      customFieldApi
        .updateFieldLabel({
          fieldId: id,
          label: newName,
        })
        .then(([, response]) => {
          const index = customFields.findIndex(
            (item) => item.id === response.id
          )

          return incorporateCustomFields([
            ...customFields.slice(0, index),
            response,
            ...customFields.slice(index + 1),
          ])
        })

    if (optimistic) {
      const index = customFields.findIndex((item) => item.id === id)
      const element = customFields[index]

      const newData = incorporateCustomFields([
        ...customFields.slice(0, index),
        { ...element, label: newName },
        ...customFields.slice(index + 1),
      ])

      return mutateSurveyCustomFieldsOptimistic({
        newObject: newData,
        mutator: apiMutator,
      })
    }

    return mutateSurveyCustomFields({ mutator: apiMutator })
  }

  const mutateDeleteCustomField = async ({ id, optimistic = true }) => {
    const apiMutator = async () =>
      customFieldApi
        .deleteField({
          fieldId: id,
        })
        .then(() => {
          return incorporateCustomFields(
            customFields.filter((item) => item.id !== id)
          )
        })

    if (optimistic) {
      return mutateSurveyCustomFieldsOptimistic({
        newObject: incorporateCustomFields(
          customFields.filter((item) => item.id !== id)
        ),
        mutator: apiMutator,
      })
    }

    return mutateSurveyCustomFields({ mutator: apiMutator })
  }

  const mutateCreateCustomField = async ({
    label,
    dataType = 1,
    order,
    optimistic = true,
    isBuildingField = true,
  }) => {
    const templateId = isBuildingField ? buildingTemplateId : listingTemplateId
    const apiMutator = async () =>
      customFieldApi
        .createField({
          templateId,
          label,
          dataType,
          order,
        })
        .then(([, response]) => {
          return incorporateCustomFields([...customFields, response])
        })

    if (optimistic) {
      return mutateSurveyCustomFieldsOptimistic({
        newObject: incorporateCustomFields([
          ...customFields,
          {
            label,
            data_type: dataType,
            order,
            template: { id: templateId },
          },
        ]),
        mutator: apiMutator,
      })
    }

    return mutateSurveyCustomFields({ mutator: apiMutator })
  }

  const mutateSetCustomFieldOrder = async ({
    fieldOrder,
    isBuildingFields = true,
  }) => {
    const templateId = isBuildingFields ? buildingTemplateId : listingTemplateId
    console.log({ fieldOrder, templateId })
    const apiMutator = async () =>
      surveyApi
        .setFieldOrder({
          surveyId: survey.id,
          fieldOrder,
          templateId,
        })
        .then(([, response]) => {
          return response
        })

    return mutateSurveyCustomFields({ mutator: apiMutator })
  }

  return {
    survey,
    customFields,
    buildingCustomFields,
    listingCustomFields,
    mutate,
    mutateChangeCustomFieldType,
    mutateChangeCustomFieldLabel,
    mutateDeleteCustomField,
    mutateCreateCustomField,
    mutateSetCustomFieldOrder,
    loading: !survey,
    error,
  }
}
