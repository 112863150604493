import useSWR from 'swr'
import _ from 'lodash'
import { useMemo } from 'react'
import { surveyApi } from '~/legacy/fetchApi'

export const useSurveyCustomFieldsSelector = ({ surveyId, enabled = true }) => {
  const key = `surveys/${surveyId}/all_custom_fields`

  const {
    data: survey,
    error,
    mutate,
  } = useSWR(enabled && surveyId ? key : null, async () => {
    return surveyApi
      .getCustomFields({ surveyId })
      .then(([, response]) => response)
  })

  const { sortedCustomFields, buildingCustomFields, listingCustomFields } =
    useMemo(() => {
      const customFields = survey?.custom_fields || []
      const buildingTemplateId =
        survey?.templates && survey?.templates.length > 0
          ? survey.templates.find((t) => t.type === 'SURVEY_BUILDINGS')?.id
          : undefined

      const listingTemplateId =
        survey?.templates && survey?.templates.length > 0
          ? survey.templates.find((t) => t.type === 'SURVEY_SPACES')?.id
          : undefined

      const sortedCustomFields = _.orderBy(
        customFields,
        [(cf) => cf.template.id, (cf) => cf.order],
        ['asc', 'asc']
      )

      const buildingCustomFields = sortedCustomFields.filter(
        (cf) => cf.template.id === buildingTemplateId
      )

      const listingCustomFields = sortedCustomFields.filter(
        (cf) => cf.template.id === listingTemplateId
      )

      return {
        sortedCustomFields,
        buildingCustomFields,
        listingCustomFields,
      }
    }, [survey])

  // FIXME: Bring the mutations from useSurveySelector here and refactor the BuildingDetailPage
  return {
    key,
    customFields: sortedCustomFields,
    buildingCustomFields,
    listingCustomFields,
    error,
    isLoading: !survey,
    mutate,
    refetch: mutate,
  }
}
